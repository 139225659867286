// DO NOT USE UMI FOR THIS: WAS NOT RETURNING THE IMAGE LINKS IN MANY CASES
// https://www.pointer.gg/tutorials/solana-nft-collection/1d5a8e1c-b857-469a-87c2-da3e9bc3a0fc

import React, { FC, useEffect, useState } from "react";
import { Metadata, Metaplex, Nft } from "@metaplex-foundation/js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
// === CUSTOM ===
import { Header } from "./header";
import { HELPERS } from "../application";
import { COLLECTION_MINT_ADDRESS } from "../src/constants";

export const MyJeos: FC = () => {
  // === VARIABLES ===
  const { connection } = useConnection();
  const wallet = useWallet();
  const metaplex = Metaplex.make(connection);
  const nfts = metaplex.nfts();

  // === STATES ===
  const [userNfts, setUserNfts] = useState<Nft[]>([]);
  const [loading, setLoading] = useState(true);

  // === CALLBACKS ===
  useEffect(() => {
    getUserNfts();
  }, [wallet.publicKey]);

  // === FUNCTIONS ===
  async function getUserNfts() {
    if (!wallet.publicKey) return;
    try {
      setLoading(true);
      // Fetch all the user's NFTs
      const userNfts = await nfts.findAllByOwner({ owner: wallet.publicKey });

      // Filter to our collection
      const ourCollectionNfts = userNfts.filter(
        (metadata) =>
          metadata.collection !== null &&
          metadata.collection.verified &&
          metadata.collection.address.toBase58() ===
            new PublicKey(COLLECTION_MINT_ADDRESS).toBase58(),
      );

      // Load the JSON for each NFT
      const loadedNfts = await Promise.all(
        ourCollectionNfts.map((metadata) => {
          return nfts.load({ metadata: metadata as Metadata });
        }),
      );

      console.log("Got their NFTs!", ourCollectionNfts);
      setUserNfts(loadedNfts as Nft[]);
    } catch (err) {
      HELPERS.toastr.showAlertDanger(err);
    } finally {
      setLoading(false);
    }
  }

  // This runs every time the component gets reloaded!
  const dataItems = userNfts.map(function (record) {
    if (record.json) {
      return (
        <div className="col-md-6 col-lg-4" key={record.json.image}>
          <div className="card border-0 bg-transparent">
            <img className="w-100" src={record.json.image} />
            <div className="text-center">
              <h5 className="fw-bold mb-0 mt-3">{record.json.name}</h5>
            </div>
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <Header />
      <div className="container" id="my-jeos">
        <h1 className="page-heading">MY JEOS</h1>
        <div className="row" id="my-jeos-grid">
          {!loading && dataItems}
          {loading && <h2 className="text-center">Loading...</h2>}
        </div>
      </div>
    </>
  );
};
