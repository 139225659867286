// https://solanacookbook.com/references/keypairs-and-wallets.html#react
import React, { useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";

type Props = {
  children?: React.ReactNode;
};

export const Wallet: React.FC<Props> = ({ children }) => {
  // The network can be set to 'Devnet', 'Testnet', or 'Mainnet'.
  const network = WalletAdapterNetwork.Mainnet;
  // const endpoint = useMemo(
  //   () =>
  //     "https://devnet.helius-rpc.com/?api-key=a179a804-e0a8-4d65-aaec-412ee67f9a16",
  // );

  // const network = WalletAdapterNetwork.Mainnet;
  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(
    () =>
      "https://mainnet.helius-rpc.com/?api-key=a179a804-e0a8-4d65-aaec-412ee67f9a16",
  );
  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/anza-xyz/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network],
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
