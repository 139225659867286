// https://developers.metaplex.com/das-api/methods/search-assets
// https://developers.metaplex.com/das-api/getting-started

// === REACT ===
import React, { useMemo, useEffect, useState, useCallback } from "react";

// === BOOTSTRAP ===
import ProgressBar from "react-bootstrap/ProgressBar";

// === DATE AND TIME ===
import date from "date-and-time";

// === REACT BOOTSTRAP ===
import Modal from "react-bootstrap/Modal";

// === SOLANA ===
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {
  getAccount,
  getAssociatedTokenAddressSync,
  getMint,
  TokenAccountNotFoundError,
} from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";

// === METAPLEX ===
import { dasApi } from "@metaplex-foundation/digital-asset-standard-api";
import {
  fetchCandyGuard,
  fetchCandyMachine,
  getMerkleProof,
  getMerkleRoot,
  mintV2,
  mplCandyMachine,
  route,
} from "@metaplex-foundation/mpl-candy-machine";
import { setComputeUnitLimit } from "@metaplex-foundation/mpl-toolbox";
import { mplTokenMetadata } from "@metaplex-foundation/mpl-token-metadata";
import {
  generateSigner,
  transactionBuilder,
  publicKey as pk,
  some,
} from "@metaplex-foundation/umi";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";

// === OTHER ===
import List from "react-list-select";
import confetti from "canvas-confetti";

// === CUSTOM ===
import {
  SNAP1,
  SNAP2,
  SNAP3,
  SNAP4,
  SNAP5,
  SNAP6,
  SNAP7,
  CANDY_MACHINE_ADDRESS,
  COLLECTION_MINT_ADDRESS,
} from "../src/constants";
import { HELPERS } from "../application";

export const CandyMint = () => {
  // === VARIABLES ===
  const { connection } = useConnection();
  const wallet = useWallet();
  const umi = useMemo(
    () =>
      createUmi(connection.rpcEndpoint)
        .use(walletAdapterIdentity(wallet))
        .use(mplCandyMachine())
        .use(mplTokenMetadata()),
    [
      wallet,
      mplCandyMachine,
      walletAdapterIdentity,
      mplTokenMetadata,
      connection.rpcEndpoint,
      createUmi,
    ],
  );
  const umiDas = createUmi(connection.rpcEndpoint).use(dasApi());

  // === STATES ===
  // THIS SHOULD BE USED FOR RENDERING PURPOSES ONLY
  const [candyMachineState, setCandyMachineState] = useState({});
  const [minting, setMinting] = useState(false);
  const [recentlyMintedNft, setRecentlyMintedNft] = useState({});
  const [guardGroups, setGuardGroups] = useState([]);
  const [listDisabled, setListDisabled] = useState([0, 1, 2, 3, 4, 5, 6, 7]);
  const [listSelected, setListSelected] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("LOADING ...");
  const [whitelistVerified, setWhitelistVerified] = useState(false);

  // === INIT ===
  useEffect(() => {
    initSetCandyMachineAndGuardGroups();
  }, []);

  // === LISTENERS ===
  useEffect(() => {
    updateGuardGroup();
  }, [wallet.publicKey, guardGroups]);

  const throwConfetti = useCallback(() => {
    confetti({
      particleCount: 800,
      spread: 130,
      startVelocity: 110,
      gravity: 0.85,
      origin: { y: 1.5 },
    });
  }, []);

  const onClick = async () => {
    if (!wallet.publicKey) {
      HELPERS.toastr.showAlertDanger("Wallet not connected!", true);
      return;
    }

    let group;
    try {
      setRecentlyMintedNft({});
      setMinting(true);
      const groupBeforeUpdate = guardGroups[listSelected[0]];
      const groupAndCandyMachine = await updateGuardGroup();
      const candyMachine = groupAndCandyMachine.candyMachine;
      group = groupAndCandyMachine.guardGroup;
      if (group && group.label == groupBeforeUpdate.label) {
        // await checkAllowList(candyMachine, group);
        setButtonLabel("MINTING...");
        // Mint from the Candy Machine.
        const nftMint = generateSigner(umi);
        const transaction = transactionBuilder()
          .add(setComputeUnitLimit(umi, { units: 800_000 }))
          .add(
            mintV2(umi, {
              candyMachine: candyMachine.publicKey,
              candyGuard: candyMachine.mintAuthority,
              nftMint,
              collectionMint: candyMachine.collectionMint,
              collectionUpdateAuthority: candyMachine.authority,
              group: some(group.label),
              mintArgs: mintArgs(group),
            }),
          );
        await transaction.sendAndConfirm(umi, {
          confirm: { commitment: "confirmed" },
        });
        await updateGuardGroup(candyMachine.itemsRedeemed);
        await showRecentlyMintedNft();
        HELPERS.toastr.showAlertSuccess("Mint successful!", true);
        throwConfetti();
      } else {
        updateGuardGroup();
        HELPERS.toastr.showAlertWarning(
          "Group changed. Please try again.",
          true,
        );
      }
    } catch (error) {
      HELPERS.toastr.showAlertDanger(error, true);
      updateGuardGroup();
    } finally {
      setMinting(false);
    }
  };

  // === FUNCTIONS ===
  // allowList route proof verification
  // https://developers.metaplex.com/candy-machine/guards/allow-list#mint-settings
  // https://developers.metaplex.com/candy-machine/guard-route#the-route-instruction
  const checkAllowList = async (candyMachine, group) => {
    let merkleRoot;
    let merkleProof;
    if (!whitelistVerified) {
      setButtonLabel("CHECKING SNAPSHOT...");
      let list;
      if (group.label == "snap1") {
        list = SNAP1;
      } else if (group.label == "snap2") {
        list = SNAP2;
      } else if (group.label == "snap3") {
        list = SNAP3;
      } else if (group.label == "snap4") {
        list = SNAP4;
      } else if (group.label == "snap5") {
        list = SNAP5;
      } else if (group.label == "snap6") {
        list = SNAP6;
      } else if (group.label == "snap7") {
        list = SNAP7;
      }
      merkleRoot = getMerkleRoot(list);
      merkleProof = getMerkleProof(list, umi.identity.publicKey);
    } else {
      return;
    }

    await route(umi, {
      candyMachine: candyMachine.publicKey,
      candyGuard: candyMachine.mintAuthority,
      group: some(group.label),
      guard: "allowList",
      routeArgs: {
        path: "proof",
        merkleRoot,
        merkleProof,
        minter: umi.identity,
      },
    }).sendAndConfirm(umi, {
      confirm: { commitment: "confirmed" },
    });
    await HELPERS.delay(10_000);
    setWhitelistVerified(true);
  };

  const mintArgs = (group) => {
    let args = {};
    if (group.label != "public") {
      args.mintLimit = some({ id: group.guards.mintLimit.value.id });
    }
    if (group.label == "snap1") {
      args.allowList = some({ merkleRoot: getMerkleRoot(SNAP1) });
    } else if (group.label == "snap2") {
      args.allowList = some({ merkleRoot: getMerkleRoot(SNAP2) });
    } else if (group.label == "snap3") {
      args.allowList = some({ merkleRoot: getMerkleRoot(SNAP3) });
    } else if (group.label == "snap4") {
      args.allowList = some({ merkleRoot: getMerkleRoot(SNAP4) });
    } else if (group.label == "snap5") {
      args.allowList = some({ merkleRoot: getMerkleRoot(SNAP5) });
    } else if (group.label == "snap6") {
      args.allowList = some({ merkleRoot: getMerkleRoot(SNAP6) });
    } else if (group.label == "snap7") {
      args.allowList = some({ merkleRoot: getMerkleRoot(SNAP7) });
    } else {
      args.solPayment = some({
        destination: pk(group.guards.solPayment.value.destination),
      })
    }
    return args;
  };

  function comp(guardGroup) {
    return (
      <div className="card guard-group mt-3 text-start">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <span className="badge bg-info-subtle">
              {guardGroup.displayLabel}
            </span>
            <div className="d-flex">
              <div className="date-container">{guardGroup.dateLabel}</div>
              {guardGroup.dateValue && (
                <span className="badge bg-info-subtle ms-2">
                  {guardGroup.dateValue}
                </span>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div className="payment-amount d-flex align-items-center">
              {guardGroup.label == "public" && (
                <>
                  <img
                    src="https://res.cloudinary.com/hv5cxagki/image/upload/f_auto,q_auto/v1/logos/Solana_logo_gdcc8w.png"
                    className="me-1"
                  />
                  {guardGroup.guards.solPayment.displayLabel}
                </>
              )}
            </div>
              {guardGroup.label != "public" && (
                <>
                  <div className="payment-amount">{`${guardGroup.guards.mintLimit.value.limit} PER WALLET`}</div>
                </>
              )}
          </div>
        </div>
      </div>
    );
  }

  const handleModalClose = () => setModalShow(false);

  // If collection can't be found it raises an error.
  // This can happen if api is behind in blocks.
  // Don't show the error.
  const showRecentlyMintedNft = async () => {
    try {
      const assets = await umiDas.rpc.searchAssets({
        owner: pk(umi.identity.publicKey),
        grouping: ["collection", COLLECTION_MINT_ADDRESS],
        sortBy: { sortBy: "created", sortDirection: "desc" },
      });
      if (assets.items[0]) {
        let response = await fetch(assets.items[0].content.json_uri);
        response = await response.json();
        setRecentlyMintedNft(response);
        setModalShow(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateGuardGroup = async (previousItemsRedeemed = undefined) => {
    try {
      let candyMachine;
      let newSelected = [];
      let newDisabled = [0, 1, 2, 3, 4, 5, 6, 7];
      if (guardGroups.length && wallet.connected) {
        candyMachine = await updateCandyMachine();
        // Refresh candy machine until new one is retrieved if previousItemsRedeemed
        if (previousItemsRedeemed) {
          while (candyMachine.itemsRedeemed <= previousItemsRedeemed) {
            await HELPERS.delay(3_000);
            candyMachine = await updateCandyMachine();
          }
        }
        let newButtonLabel;
        if (candyMachine.soldOut()) {
          newButtonLabel = "SOLD OUT";
        }
        let currentDateTime = new Date();
        for (const guardGroup of guardGroups) {
          // Update dateLabel: This will be rendered as and changes here will be reflected in the listSelected and listDisabled states
          setGuardGroupDateLabels(candyMachine, guardGroup);
          // set guard group via listSelected listDisabled
          if (!newSelected.length && !candyMachine.soldOut()) {
            if (currentDateTime >= guardGroup.guards.startDate.value.date) {
              if (guardGroup.label == "snap1") {
                if (currentDateTime < guardGroup.guards.endDate.value.date) {
                  if (SNAP1.includes(wallet.publicKey.toBase58())) {
                    newDisabled = [1, 2, 3, 4, 5, 6, 7];
                    newSelected = [0];
                    newButtonLabel = "MINT NFT";
                  } else {
                    newButtonLabel = "WALLET NOT ELIGIBLE FOR CURRENT PHASE";
                  }
                }
              } else if (guardGroup.label == "snap2") {
                if (currentDateTime < guardGroup.guards.endDate.value.date) {
                  if (SNAP2.includes(wallet.publicKey.toBase58())) {
                    newDisabled = [0, 2, 3, 4, 5, 6, 7];
                    newSelected = [1];
                    newButtonLabel = "MINT NFT";
                  } else {
                    newButtonLabel = "WALLET NOT ELIGIBLE FOR CURRENT PHASE";
                  }
                }
              } else if (guardGroup.label == "snap3") {
                if (currentDateTime < guardGroup.guards.endDate.value.date) {
                  if (SNAP3.includes(wallet.publicKey.toBase58())) {
                    newDisabled = [0, 1, 3, 4, 5, 6, 7];
                    newSelected = [2];
                    newButtonLabel = "MINT NFT";
                  } else {
                    newButtonLabel = "WALLET NOT ELIGIBLE FOR CURRENT PHASE";
                  }
                }
              } else if (guardGroup.label == "snap4") {
                if (currentDateTime < guardGroup.guards.endDate.value.date) {
                  if (SNAP4.includes(wallet.publicKey.toBase58())) {
                    newDisabled = [0, 1, 2, 4, 5, 6, 7];
                    newSelected = [3];
                    newButtonLabel = "MINT NFT";
                  } else {
                    newButtonLabel = "WALLET NOT ELIGIBLE FOR CURRENT PHASE";
                  }
                }
              } else if (guardGroup.label == "snap5") {
                if (currentDateTime < guardGroup.guards.endDate.value.date) {
                  if (SNAP5.includes(wallet.publicKey.toBase58())) {
                    newDisabled = [0, 1, 2, 3, 5, 6, 7];
                    newSelected = [4];
                    newButtonLabel = "MINT NFT";
                  } else {
                    newButtonLabel = "WALLET NOT ELIGIBLE FOR CURRENT PHASE";
                  }
                }
              } else if (guardGroup.label == "snap6") {
                if (currentDateTime < guardGroup.guards.endDate.value.date) {
                  if (SNAP6.includes(wallet.publicKey.toBase58())) {
                    newDisabled = [0, 1, 2, 3, 4, 6, 7];
                    newSelected = [5];
                    newButtonLabel = "MINT NFT";
                  } else {
                    newButtonLabel = "WALLET NOT ELIGIBLE FOR CURRENT PHASE";
                  }
                }
              } else if (guardGroup.label == "snap7") {
                if (currentDateTime < guardGroup.guards.endDate.value.date) {
                  if (SNAP7.includes(wallet.publicKey.toBase58())) {
                    newDisabled = [0, 1, 2, 3, 4, 5, 7];
                    newSelected = [6];
                    newButtonLabel = "MINT NFT";
                  } else {
                    newButtonLabel = "WALLET NOT ELIGIBLE FOR CURRENT PHASE";
                  }
                }
              } else {
                newDisabled = [0, 1, 2, 3, 4, 5, 6];
                newSelected = [7];
                newButtonLabel = "MINT NFT";
              }
            }
          }
        }
        setButtonLabel(newButtonLabel);
      }
      setListDisabled(newDisabled);
      setListSelected(newSelected);
      return {
        candyMachine,
        guardGroup: guardGroups[newSelected[0]],
      };
    } catch (err) {
      HELPERS.toastr.showAlertDanger(err);
    }
  };

  const updateCandyMachine = async () => {
    try {
      const candyMachine = await fetchCandyMachine(
        umi,
        pk(CANDY_MACHINE_ADDRESS),
      );
      candyMachine.soldOut = () => {
        return candyMachine.itemsRedeemed == candyMachine.data.itemsAvailable;
      };
      candyMachine.progressPercentage =
        Number(
          (10000n * candyMachine.itemsRedeemed) /
            candyMachine.data.itemsAvailable,
        ) / 100;
      setCandyMachineState(candyMachine);
      return candyMachine;
    } catch (error) {
      HELPERS.toastr.showAlertDanger(error.message, true);
    }
  };

  const initSetCandyMachineAndGuardGroups = async () => {
    try {
      // Fetch the Candy Machine.
      const candyMachine = await updateCandyMachine();
      // Fetch the Candy Guard.
      const candyGuard = await fetchCandyGuard(umi, candyMachine.mintAuthority);
      let mint;
      let formattedGroups = candyGuard.groups.map(function (guardGroup) {
        guardGroup.guards.startDate.value.date = new Date(
          Number(guardGroup.guards.startDate.value.date) * 1_000,
        );
        if (guardGroup.guards.endDate.value) {
          guardGroup.guards.endDate.value.date = new Date(
            Number(guardGroup.guards.endDate.value.date) * 1_000,
          );
        }
        // set date label
        setGuardGroupDateLabels(candyMachine, guardGroup);

        if (guardGroup.label == "snap1") {
          guardGroup.displayLabel = "VIP 1";
        } else if (guardGroup.label == "snap2") {
          guardGroup.displayLabel = "VIP 2";
        } else if (guardGroup.label == "snap3") {
          guardGroup.displayLabel = "VIP 3";
        } else if (guardGroup.label == "snap4") {
          guardGroup.displayLabel = "VIP 4";
        } else if (guardGroup.label == "snap5") {
          guardGroup.displayLabel = "VIP 5";
        } else if (guardGroup.label == "snap6") {
          guardGroup.displayLabel = "VIP 6";
        } else if (guardGroup.label == "snap7") {
          guardGroup.displayLabel = "VIP 7";
        } else {
          guardGroup.displayLabel = "Public";
          guardGroup.guards.solPayment.displayLabel = `${Number(guardGroup.guards.solPayment.value.lamports.basisPoints) / parseFloat("1" + "0".repeat(guardGroup.guards.solPayment.value.lamports.decimals))} ${guardGroup.guards.solPayment.value.lamports.identifier}`;
        }
        return guardGroup;
      });
      setGuardGroups(formattedGroups);
    } catch (err) {
      HELPERS.toastr.showAlertDanger(err);
    }
  };

  const setGuardGroupDateLabels = (candyMachine, guardGroup) => {
    let currentDateTime = new Date();
    if (candyMachine.soldOut()) {
      guardGroup.dateLabel = "ENDED";
      guardGroup.dateValue = undefined;
    } else {
      if (currentDateTime < guardGroup.guards.startDate.value.date) {
        guardGroup.dateLabel = "STARTS";
        guardGroup.dateValue = `${date.format(guardGroup.guards.startDate.value.date, "DD MMM YYYY HH:mm", true)} UTC`;
      } else if (guardGroup.guards.endDate.value) {
        if (currentDateTime < guardGroup.guards.endDate.value.date) {
          guardGroup.dateLabel = "ENDS";
          guardGroup.dateValue = `${date.format(guardGroup.guards.endDate.value.date, "DD MMM YYYY HH:mm", true)} UTC`;
        } else {
          guardGroup.dateLabel = "ENDED";
        }
      } else {
        guardGroup.dateLabel = "IN PROGRESS";
      }
    }
  };

  return (
    <>
      <List
        items={guardGroups.map(function (guardGroup) {
          return comp(guardGroup);
        })}
        selected={listSelected}
        disabled={listDisabled}
        multiple={false}
        // We don't need this as the group will be selected for the user
        onChange={(selected) => {
          console.log(selected);
        }}
      />
      <div className="mb-3">
        {!wallet.connected && <WalletMultiButton />}
        {wallet.connected && (
          <button
            className="btn text-center mt-3 w-100 button-red"
            disabled={minting || listSelected.length == 0}
            onClick={onClick}
          >
            <span>{buttonLabel}</span>
          </button>
        )}
      </div>
      {candyMachineState.itemsRedeemed && (
        <>
          <ProgressBar now={candyMachineState.progressPercentage} />
          <div className="d-flex justify-content-between mt-1">
            <div>Total Minted</div>
            <div className="d-flex">
              <div className="me-1">
                {candyMachineState.progressPercentage}%
              </div>
              <div>
                ({Number(candyMachineState.itemsRedeemed)}/
                {Number(candyMachineState.data.itemsAvailable)})
              </div>
            </div>
          </div>
        </>
      )}
      <Modal show={modalShow} onHide={handleModalClose} centered>
        <Modal.Body className="position-relative">
          <div
            className="d-flex align-items-center position-absolute justify-content-center border-1 bg-white"
            onClick={handleModalClose}
            style={{
              borderRadius: "25px",
              cursor: "pointer",
              height: "40px",
              width: "40px",
              right: "-10px",
              top: "-12px",
            }}
          >
            <i className="bi bi-x fs-1"></i>
          </div>
          <img src={recentlyMintedNft.image} className="w-100" />
          <h5 className="mb-0 mt-3">{recentlyMintedNft.name}</h5>
        </Modal.Body>
      </Modal>
    </>
  );
};
