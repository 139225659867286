import React, { FC } from "react";
import { CandyMint } from "./candy_mint";
import { Header } from "./header";
import { Footer } from "./footer";

export const Home: FC = () => {
  return (
    <>
      <Header />
      <div className="container" id="home">
        <div className="row">
          <div className="col-12">
            <div className="stage-container position-relative w-100">
              <img
                className="stage w-100 z-0"
                src="https://res.cloudinary.com/hv5cxagki/image/upload/v1730623095/trumppets/stage_full_e5yymu.png"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="card text-center" id="mint-card">
              <h5>THE STAGE IS SET!</h5>
              <p>
                Time to get ready for the most important election, and mint, in
                American history.
              </p>
              <CandyMint />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="social-wrap">
              <h6>SHARE</h6>
              <div className="d-flex">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://x.com/Trumppets2024"
                >
                  <i className="bi bi-twitter-x social-icon fs-1"></i>
                </a>
                <a
                  className="mx-3"
                  target="_blank"
                  rel="noopener"
                  href="https://t.me/+1GUZjDkaduI3MTk1"
                >
                  <i className="bi bi-telegram social-icon fs-1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
