import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";

export const Header: FC = () => {
  const { connected } = useWallet();
  const navigate = useNavigate();

  return (
    <header className="pt-3 pb-4">
      <div className="container d-flex justify-content-between d-flex align-items-center">
        <Link to="/">
          <img
            className="profile jb-profile"
            src="https://res.cloudinary.com/hv5cxagki/image/upload/c_scale,dpr_2,h_100,q_auto/v1730545395/trumppets/Logo_xxla2a.png"
          />
        </Link>
        <div className="d-flex align-items-center">
          <WalletMultiButton />
        </div>
      </div>
    </header>
  );
};
