import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";

document.addEventListener("turbo:load", () => {
  const root = document.getElementById("root");
  if (root) {
    createRoot(root).render(<App />);
  }
});
