import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "../components/home";
import { MyJeos } from "../components/my_jeos";

export default (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/my_jeos" element={<MyJeos />} />
    </Routes>
  </Router>
);
