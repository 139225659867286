import "@hotwired/turbo-rails";
import "./src/jquery";
import "bootstrap";
import "./src/buffer";
import toastr from "toastr";

// === CUSTOM ===
import "./components";

// declare global {
//     interface Window { x: any; }
// }

// === HELPERS ===
export const HELPERS = {
  toastr: {
    showAlertInfo: function (text, autoHide = true) {
      toastr.options.closeButton = true;
      toastr.options.closeDuration = 0;
      toastr.options.extendedTimeOut = 0;
      toastr.options.preventDuplicates = true;
      toastr.options.tapToDismiss = false;
      toastr.options.timeOut = 0;
      if (autoHide) {
        toastr.options = {
          progressBar: true,
          preventDuplicates: false,
          showDuration: 300,
          hideDuration: 1000,
          timeOut: 5000,
          extendedTimeOut: 1000,
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut",
        };
      }
      toastr.info(text);
    },
    showAlertWarning: function (error, autoHide = true) {
      toastr.options.closeButton = true;
      toastr.options.closeDuration = 0;
      toastr.options.extendedTimeOut = 0;
      toastr.options.preventDuplicates = true;
      toastr.options.tapToDismiss = false;
      toastr.options.timeOut = 0;
      if (autoHide) {
        toastr.options = {
          progressBar: true,
          preventDuplicates: false,
          showDuration: 300,
          hideDuration: 1000,
          timeOut: 5000,
          extendedTimeOut: 1000,
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut",
        };
      }
      toastr.warning(error);
    },
    showAlertDanger: function (error, autoHide = false) {
      if (
        typeof error === "string" &&
        error.includes("Converting circular structure")
      ) {
        return;
      }

      toastr.options.closeButton = true;
      toastr.options.closeDuration = 0;
      toastr.options.extendedTimeOut = 0;
      toastr.options.preventDuplicates = true;
      toastr.options.tapToDismiss = false;
      toastr.options.timeOut = 0;
      if (autoHide) {
        toastr.options = {
          progressBar: true,
          preventDuplicates: false,
          showDuration: 300,
          hideDuration: 1000,
          timeOut: 5000,
          extendedTimeOut: 1000,
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut",
        };
      }
      toastr.error(error);
    },
    showAlertSuccess: function (text, autoHide = false) {
      toastr.options.closeButton = true;
      toastr.options.closeDuration = 0;
      toastr.options.extendedTimeOut = 0;
      toastr.options.preventDuplicates = true;
      toastr.options.tapToDismiss = false;
      toastr.options.timeOut = 0;
      if (autoHide) {
        toastr.options = {
          progressBar: true,
          preventDuplicates: false,
          showDuration: 300,
          hideDuration: 1000,
          timeOut: 5000,
          extendedTimeOut: 1000,
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut",
        };
      }
      toastr.success(text);
    },
  },
  delay: async (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, ms);
    });
  },
};
