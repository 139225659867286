// === DEVNET ===
// export const CANDY_GUARD_ID = "C5PMwVPbPVGmXUPDyujg5j6oi9mcpABofYhYJhrUS14";
// export const CANDY_MACHINE_ADDRESS =
//   "AZGzztMTzH1yxo7jqGWMZQs7YJpUY7dpnSXaqjd7jJL8";
// export const COLLECTION_MINT_ADDRESS =
//   "DR81BmjRhQGQZmsDQcACYt6qLZtY8uzSuBaoqjxNXB6A";

// === MAINNET ===
export const CANDY_GUARD_ID = "5mT9Xv92aD8L9L3HwuzMkDnvRh3b5AuG5ReZ2rAYy2aP";
export const CANDY_MACHINE_ADDRESS =
  "9ouGXPX2ZsWwRxjzPVVc1F8m6e9n2ShWdnVPXCEAtEQG";
export const COLLECTION_MINT_ADDRESS =
  "Fx5oejGnWrPsGZPZgSy3vB8jZZGCT7VCYwqE1qHbeTm4";
export const SNAP1 = [
  "4HA6uLrjPJHVaUJziTsmghLT869eZ5LFiLbqme66Hrh9",
  "4LW7S4hemYC7i2AV737vXVsBz99t665em64PAxcGQJEV",
  "57z6AozREq3XK9yiBBHvm88HRpvFh33nAoc6GgCgBzNa",
  "w8tMRKorL4wNfw3UL7aWQFFFiKcmbWArxNJ86Zq1zkL",
  "4N4ekrHQEy8qgt8vmpHjQLXpAm1Wtb8RL7ghYBBxfq8E",
  "FDGPFyHCVvddM4mLZi1Sazj1pFFxDaYos24R4CQrM4Dj",
  "FqAEtY5r7PMcEatkPFLgJ8JrKfBHqKdwkuf6FSNSaPwz",
  "Dk4iCjtj7usGKSp61WQTvqmvQw6f8FXFWtyteG9a8tUA",
  "8xneu1mKT7ueUPStLw5YJ5Cn3VzkHTSi3DgQ1rmWseow",
  "JD2VS3ZXuHQ3J4zaMNVPr9XJLWyULWLhXbuWsrKtjSU",
  "UimjAmcRQFA5UXDxoqHZfJKeBMsoFhgcqGgLLqwMTin",
  "GqtL2CR2ouU4KYY6WG4P1iDAVyo7dxHuWHFjRAtZ7yZX",
  "7ySywcBk7SLL9BsW9sMUbxSSoCB27b6geLQEevBzuPyo",
  "5GEo6HZC65aqH6WgSdSJsE3ZuEdpQmN6hnGzgoXRymP2",
  "6GxnhUo3VHmzpXd1wc6hBA63igHdsUzJj9ZVecRpSTTV",
  "9D8D6ToJWZtsnTMhGTRhWJSTstfVwKcV5GvH4vaABdy3",
  "6cZry4CRj9ckKJNw9WN5myXf19GoS7VstKKSQAosfBB7",
  "8JRsYBHN7CP3pAYfSGRJdLj1WFeSaPKY2qeGQQhqBFXo",
  "EjDT2s6D3BiCqu1SpEfXJ385G5KntsKHbjovn4mzKxZ8",
];
export const SNAP2 = [
  "2c57izEWCPNQxUaM7cPL2Phfaok1KpUXBoeBAVwena2G",
  "2nwxU2xTS6ceyLNYbvEnysRn4wrsoLcy3xd4cz3U6osn",
  "3GrutM5mjCXw3Nm6tfKqsazE1Po93YiHRwzV2JK8sSHL",
  "3uy7PtVJZauVKALb96PBVXAmbMksqnGVCUuJi4NVnF25",
  "48LG5CfvQKnCMY7d5HzMhcvKMYgRgSKVDfjtfe3Mphd6",
  "4KLEPMGpTBeWu1KNa3ejGDf6j4fkSxf2ZkE2DH9eCKQy",
  "4w4ArgMZWxVchMqza5gKzcVdanCNn3ea6f1VjJdqVLTi",
  "5AGGGBB1LYhJLA2wYqdujd3mPsrjqw3wd3qGgfQkGy5S",
  "5XZQRxrtpGD2yGkLcBvWoeSWbJiq25mFkbPhWDsSu154",
  "5xw9aGBQznfWPwi1Tg9z8AYcLfS62pgPz8BwDhAv2twg",
  "6RaAhN7zdnWqaGHpu9sfzpmqp4WrDrRDfecbPX2LA4TA",
  "6i1pWqZzibtWNVnsCb4cqjYnqcRJSzZU3tw6LVfHQzYt",
  "7JBUhzucr6wUt7QeNrdf7XThdYnAWwc3GVXoB5orizS9",
  "7aaRa2bZzFQMPuLMkDhh2Uo67xeSSVUac81Ychbq1jdH",
  "7arRJTkAggy5ma2Nqz42sLXWZKjfZsGPgEimUCRkQqhv",
  "7iTJxSNfQ5Ej3VJ9aJf4Cru4DZTw45NN7jYpwQEVvtFf",
  "835Nnnyspuz6VE2PRUW1gCBHHBXRHve9Vv6JDYY211YX",
  "8WNvB2SKt5LD4abCDLnJxPVSGbn7NNteDc2dZNFPVS2F",
  "8oPHRhnxMrQ99qU3qZ1HNvgag8d3uVgdaEPN56nxiKaj",
  "95FMfKr7R4VnLgEcXAmnqA1PwMxEFvLz8GzbLRRUhwGC",
  "9Zyp7X3dXi2cxskuDKv6VUEnoPcBysSGxtheXetefNpD",
  "9gdEXFLnahmawKW5oZjgNfBADSNUvRSKosyRriA7jE6B",
  "Axoh16ebD8gyo6nZDNP7zDVBBNwjXSHWH6RUcqUuKG6j",
  "BNn2817zNpiHqYwUR5JBzxPYdnmbVsjzMVXncuCNAzUa",
  "BaiEnJNwzYUy2cXiDCdjxTSGVaZEhyJgUUchVow1G2P3",
  "C7Hkup73TDH7JuUPVGNYJhrXpmWh8ST6nnLEAaBa3Wkz",
  "CVhCttyPw9asFQsqUD1h7LRh3TQnnHNSdxXzdeyxsQDy",
  "Ch6caTTb12HzaXL7nn1qi67Jx2nbB5eM28NfKmgmUsqE",
  "Cuj6EKxnkgqRXje64mhCBP2aBK68jvKLViCfQVdaetHU",
  "DTEMgK3XYM92tnoNb9wqXJcYeEtebKxq8VVzNeWfCd2F",
  "DXNy7LM286VtiMGqivguh9PPh7Qd8AYUzoqXPp46btvP",
  "DaqTGs4RYA7vAm71wXC6JkzV4NL6eMHfH8uWYgZUzwWi",
  "DymMcQRNmyxfD9xsiN5rZfs2H8QDpDs5upGdNfDXPJKH",
  "E9uo15YhYGjYTLjikuyTfDsLk6fZBobpMXciF6MYdd7g",
  "EcyLEbxRU4eXVDJ5zuGUu6wMWUHnPmUkx2Hso2VMyNHG",
  "G4ZRa1U73cKmJ8jfQSo7oqvZEeN9BFg8sT1nYSsnSUnk",
  "GLBMPgYQgHFXUCZLiTp2KW3e6StWcxHAyX2dq5z72Eha",
  "GnQiuLNw76sasmzKea6PnBZskjzHjyfGJttu7boSGuej",
  "HVUvCAZkfnEGBv6d2M7PgytXMCmgoEfWnQjy87k3gKaQ",
  "HZWhUMc87ihJfnf26fiyAH4cARxpJYdUu9hMocu9DXUQ",
  "HqscAc6wN4NRHPXEeZ51wMEwZcVHRS4czLzm6GQSakqj",
  "J3HwVEQkZqjqztGnQW6VwXKx79ingmXHBL1E4ibDRnmt",
  "J9oKbsY5fSjZmrzKPc3if4sTGW2hgAaiiWyAdxy53rDR",
  "2M7o66nDM3oR8wERLNF9X1io1f1pnumhQhhK9mZoWSKN",
  "3Q8oGH3ioAVhGwbs5Za68MPZNr9f3MfvQXVvnhvMVtmj",
  "429ZzxgqTCRxZudMFXk2qCMDN3nQotCrJrXhKS7gN77D",
  "47FBR7Q75AXEEXXccwXzzdagazHzjyDC4aQDkHYs8HHQ",
  "JBGyxsXL1HGWGNZ84w18tXedNctA1o34b3AsFsN3xcPH",
  "LEXdMzat9XMyhBpgvijBFWqcfh1ek5gmjDUPfTyDxfv",
  "3zecYy6aww2iFYG5XhurdoWoBoGQK9LTxc2bSRUjPbL6",
  "DDQhtacCzRJ46yQJjuuGiA7YX9BFRLavcTDZyN9yJ4DP",
  "8VwFzN6pGsxj1HcHi9ApdHUPP4BeBaGppBeQMdmgNa1b",
  "EXg7ifMRT2UQE2KxjEZVAuvbKPxjBUJTVW8WYqinF7y9",
  "4YY9N8pDs7Zptd7cZvEucMYhT4zgxdYmjtd8kyozupkc",
  "EZ9CLXzjd3GSLJA2xqwpNY77NVrqRe2iDKs9QTPJ4JHa",
  "BpkUptTvt4DxmLgPCwDodFKFNpnT4SNTPB8gUX6p754D",
  "48uJjyPZprZf3X6EA3dnpoY1QJTLotmFDUc4kV38sVyY",
];
export const SNAP3 = [
  "2VxYZHaNMhyuwoidb4rvKmD6U6dHNybXNPw23JFnvsyF",
  "2sR43ACsBYdC3jnEBvjQ1B8jKg6XtNG6zHVf8XSh8pQb",
  "31uwFuquP3zYcVe3fz1WJccVuA4oDjoJZFbLwAWHUrU6",
  "3ag4NCK7WhWp1iWwaBaNRvwAp9iNM7Tv2KhU7A9aZy5H",
  "3mhJ7zkfvCyou4LFLWjZz6Exy4t5uzREThU6FTP5Cb2e",
  "4DDdffAeHJLEoBrFcEvFhsbCdtzLe9MzTXy4D2bRYBEj",
  "5ooqSQZip4Y7BKoHMtVXT77fyz9UZPx4g7aw4atbVcJe",
  "5sxU72NzxyN8ZgZ7EeDCx4MUCBoFqZPjtjtoFjLT7gcV",
  "61hqAs5mDAFdNtqGPiSxqTx8nJDxqvGbscEDorxRb1BT",
  "66q9yUo1m3vvAEgCd1U37LiomMuSXpy4WmQkD38g1wpe",
  "6wRuTRNjt9NFWWtrMCkRwjdREN2QBhJnUKspsA7mc6T6",
  "7CgiywL5PL4E2X6x35otSdUVFXgWBKtQYEpMELLB3eBN",
  "7KmP6xe8QjE8MC3i87fjA573GCqzVZSAQkCUYi4c8fWo",
  "7bZKLy8sKtq2PGVNavYzycdphrYgpnPw8TF6ukxpPG63",
  "7y1MPzzeBEWJh7TE96RN6GiEPD8ER6ftjM8Wmgd6Sh55",
  "83rrnaTFRYFqDcMoBwR4KMPaZwoFhuxVCFJ2vsFceuCG",
  "8mwEF62ZauYE3ttuJXXaeZVj8tPCKEYoDH6dyfAsq6k1",
  "8nv5zK1Af5HpCTiGaMFku1Jiced9nzaGGFpuyQtMxjmM",
  "95LTx62cS254snyhhr6bW4LMQvWSFTJtHUA3pwkW7EHw",
  "9YKwqqtdWGeoxPcKorEzAxqGMBRCRPcQb9K2aXefDgxA",
  "ALc67eJAEcLE8HD8PcF7pL2RnUk37xiDrMSdffpAPc7z",
  "AUGAMpTAhQE95yu3FWRFTBoVKVJesH6anCH7vE5Vtzkb",
  "B7myunC5TDJe6ypmaAwSSNpC3V6JqXiYDJCjgTvQvLYt",
  "D8ZXkjpPzpqV4hqKJjhaEmBjzpMVytgNuAPrsuYyG9iU",
  "DDyBqm26VUFAARrcNz5wFkUzYMtGAy1xyUnaaHZ9jBvm",
  "Dk9thwJC7PahjEfGS8XuQnCZxUkeSCEHvidQti5DnY1e",
  "HEjtXLquASFsgTTfsPBYS6astHWGjnsFCLVyM5E1F2hF",
  "HL7Bt42L9jD17jhcdAHiwsnnQnu9DtSwHXJCB85eGKE3",
  "HWMNTxDLy9yj6frmQSY9TCkpL5EeNSLgkdghCxQUneUR",
  "HqyK9BV4qJvRRL8TYimeCawLrxcxfnERZTCRAoxTKJtU",
  "JDoGj1vx3WzQHskA9VyMyKmRsZZD14USU52JZZKMAyAh",
  "dingDV5oSTQftBibP3rmM9F7G98K8ZerqnStYUETcVh",
  "h57ypN7WLJHY8tfVn1WhBbPFrFqmomDN1Ui4nAHayXA",
  "271QhFs8SfaiJZwcJ2Dxro5ii6Xtcsco6Vy1EiAtSmKu",
  "6GmbAP67b6TJKkgi3TuDiyv8hg5mjs9K1N18NVXuzJz9",
  "2E3nfd7pUcDWACPq9ijJFuFNG2q7tSLgBFmXSsFGTADm",
  "DmkzpdQAYdUoKpBfmMDDtwVMRjN668AzsJ4iUuyyjvnx",
  "a2oDLkfm5p6jJaEauG2MfyhNxoEj2C7RxnipgQTnti6",
];
export const SNAP4 = [
  "7S3VjYks5QPr11TBYYQ96Cq4X5wX2KrQaiNZh8vf9LbU",
  "Gu65imBzuAsd6m7gSpiHmMkwZRx18aUiNh62HM8vzCv2",
];
export const SNAP5 = [
  "AcM4H5P6z9q4fgwTxmWDKbFYCe4Sh4V8xtGmuNcemm7V",
  "Cm8hTeBEewaUbRpwaFyZ3josaQ4cTLuUxmTKsXsYkKC2",
  "EZy6Btii5H5v7zu2hp2BWD4fTmXKHacbs2Sty6TGrgph",
];
export const SNAP6 = [
  "ErNKPzqv3eXwaYC8adfgFpuPcyXYFNpwk68NTAbP9LGY",
  "HZXdWbA9RPHpRGdau1ojfnj7GoM7eRP6D9khA9hTY3gx",
];
export const SNAP7 = ["81exKdzkkuLfS46gfSmPiezSGD1kdWBbRduHS11Um8GT"];
